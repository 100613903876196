<template>

  <div>
    <list-template
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :loading="loading"
        has-add
        @onAdd="$router.push('./add')"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="search=null"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
    ></list-template>
    <el-dialog :visible.sync="visible" title="考试类型详情">
      <el-descriptions :column="1">
        <el-descriptions-item label="名称">{{ description.name }}</el-descriptions-item>
        <el-descriptions-item label="考试范围">{{ description.scopeName }}</el-descriptions-item>
        <el-descriptions-item label="适用校区">
          <div>
            <el-tag type="info" style="margin-right: 10rem;margin-bottom: 10rem;" v-for="item in description.school_names">{{item}}</el-tag>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{ description.note }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data(){
    return {
      loading:false,
      tableConfig:[
        {
          prop:"name",
          label:"考试类型名称",
        },
        {
          prop:"scopeName",
          label:"考试范围",
        },
        {
          prop:"creator",
          label:"创建人",
          width:"150rem"
        },
        {
          prop:"created_at",
          label:"创建时间",
          width:"180rem"
        },
        {
          prop:"handle",
          width:"140rem",
          label:"操作",
          handle:true,
          buttons:(row) => {
            if (this.$store.getters.userInfo.userinfo.id == row.creator_id) {
              return [{ type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
            }
            return [{ type:"view", text:"查看" }]
          }
        },
      ],
      search:null,
      total:0,
      tableData:[],
      visible:false,
      description:{},
      schoolList:[],
    }
  },
  computed:{
    ...mapGetters(["userInfo"]),
    ...mapState(["page"])
  },
  methods:{
    ...mapMutations(["setPage"]),
    tableHandle(row,config){
      const { type } = config
      switch (type){
        case "edit":
          this.$router.push({path:"./edit",query:{id:row.id}});
          break;
        case "delete":
          this.$_axios.delete("/exam-type/"+row.id,{
            isErrorMessage:false,
            params:{
              version:row.version,
            }
          }).then(res=>{
            this.$confirm("删除此考试类型？","提示",{
              confirmButtonText:"删除",
              cancelButtonText:"点错了",
              type:"warning"
            }).then((res)=>{
              this.$_axios.delete("/exam-type/"+row.id,{
                params:{
                  version:row.version,
                  confirm:1
                },
              }).then(res=>{
                this.getData();
                this.$message.success("删除成功");
              })
            }).catch(err=>{

            });
          }).catch(err=>{
            let {error} = err.data;
            this.$confirm(error.errorUserMsg,"提示",{
              type:"warning",
              confirmButtonText:"我知道了",
              showCancelButton:false
            })
          })
          break;
        case "view":

          // 获取初始数据
          this.$_axios.get("/exam-type/"+row.id).then(res=>{
            let {data} = res.data;
            data.scope += "";

            this.description = data;
            this.visible = true;
          })
          break;
      }
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData();
    },
    // 获取数据
    getData(){
      let {search,page} = this;
      this.loading = true;
      this.$_axios.get("/exam-type",{params: {...search,sort:"-created_at",page}}).then(res=>{
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      }).finally(()=>this.loading = false);
    },
    // 搜索功能
    onSearch(val){
      this.setPage(1);
      this.search = val;
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  activated() {
    this.getData();
  },
}
</script>

<style scoped>

</style>
